import React from 'react';
import styles from './style';

/**
 * Super simple implementation of a time-series line chart with live scrolling
 * 
 * @prop {array} Array of coordinates in format { t: unixTime, y: 0-100 }
 */
export default class LineChart extends React.Component {
	getData () {
		let { data, duration } = this.props;

		let end = Date.now() / 1000;
		let start = end - duration;

		let plots = [[]],
			prev = data[0],
			last = data[data.length - 1];

		if (!data.length) return plots;

		for (let d of data) {
			if (d.t < start) {
				prev = d;
				continue;
			}

			plots[0].push({
				x: (100 * (d.t - start)) / duration,
				y: d.y,
			});
		}

		if (prev.t < start) {
			plots[0].unshift({
				x: (100 * (prev.t - start)) / duration,
				y: prev.y,
			});
		} else {
			plots.unshift([
				{
					x: 0,
					y: prev.y,
					d: true,
				},
				plots[0][0],
			]);
		}

		if (last && last.t < end) {
			plots.push([
				{
					x: (100 * (last.t - start)) / duration,
					y: last.y,
					d: true,
				},
				{
					x: 100,
					y: last.y,
					d: true,
				},
			]);
		}

		return plots;
	}

	render () {
		let points = this.getData();
		let { colour = '#FF0000' } = this.props;

		return (
			<div className="line_chart">
				<svg viewBox='0 0 100 50' className='line_chart_graph'>
					{points
						.filter((p) => p.length)
						.map((p) => (
							<polyline
								fill='none'
								stroke={colour}
								style={{ opacity: p[0] && p[0].d ? 0.5 : 1 }}
								stroke-width='1'
								stroke-dasharray={
									p[0] && p[0].d ? '2' : undefined
								}
								points={p
									.map(
										(o) => o.x + ',' + 0.98 * (50 - o.y / 2)
									)
									.join(' ')}
							/>
						))}

					<text
						x='2'
						y='1.1em'
						style={{ fontSize: '8pt' }}
						fill={colour}
					>
						{ this.props.label }
					</text>

					<text
						x='98'
						y='1.1em'
						style={{ fontSize: '8pt' }}
						fill={colour}
						text-anchor="end"
					>
						{ points[points.length - 1]?.length ? ((points.pop()?.pop()?.y ?? -1) | 0) + '%' : '-' }
					</text>
				</svg>
			</div>
		);
	}
}
