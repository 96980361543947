import React from 'react';

import LineChart from './LineChart';

import './style';

const args = Object.fromEntries(new URLSearchParams(window.location.search).entries());

const fields = {
	'cpu': {
		label: 'C',
		colour: 'red',
		threshold: 70
	},
	'ram': {
		label: 'M',
		colour: 'lightblue',
		threshold: 70
	}
}

function scaled (db) {
	return (Math.min(0, Math.max(-90, db)) + 90) / 0.9;
}

export default class App extends React.Component {
	state = {
		hosts: {},
		args
	}

	update = () => {
		this.forceUpdate()
	}

	componentDidMount () {
		this.timer = setInterval(this.update, 500)

		this.connect();
	}

	updateTimer () {
		if (this.realtime) return;

		clearInterval(this.timer)
		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			console.log('Connected!', this.state.hosts)

			this.update()
			this.realtime = true;
			this.timer = setInterval(this.update, 500);
		}, 100);
	}

	realtime = false;
	backOffTimer = 1;

	async connect () {
		let access_token = this.state.args.access_token ?? '';

		let { services, channel } = await (
			await fetch(
				(this.state.args.server ?? '') +
				'/services?access_token=' + access_token
			)
		).json()

		let channels = Array.isArray(channel) ? channel : channel.split(',');
		let allChannels = channels.includes('*');


		if (allChannels || channel.includes(this.state.args.channel)) {
			channel = this.state.args.channel ?? channel;
		}

		this.setState({
			hosts: Object.fromEntries(
				Object.entries(services)
				.filter(serv => !this.state.args.channel || channel.includes(serv[0]))
				.map(
					([s, v]) =>
						[
							s,
							{
								...v,
								p: [-90, -90],
								r: [-90, -90]
							}
						]
				)
			)
		})

		if (this.events) {
			try {
				this.events.close()
			} catch (e) {
			}
		}

		let windowLength = this.state.args.window ?? 60;
		let windowStart = ((Date.now() / 1000 | 0) - windowLength)

		if (!channel)
			return;

		this.realtime = false;

		let data = new URLSearchParams({
			cf_connect: JSON.stringify({
				data: {
					access_token: access_token,
					channel: channel.split(','),
					last_event_id: windowStart
				}
			})
		})

		this.events = new EventSource(
			(this.state.args.server ?? '') +
			'/sub?' + data
		)

		this.events.onerror = () => {
			setTimeout(() => this.connect(), Math.min(5000, 500 * this.backOffTimer++));
		}

		this.events.onopen = () => {
			this.backOffTimer = 1;
		}

		this.events.addEventListener('message', (e) => {
			console.log('got', e.data);

			try {
				let json = JSON.parse(e.data)
				if (json.pub) {
					handleMessage(JSON.parse(json.pub.data))
				}
			} catch (e) {
				console.error(e)
			}
		})

		const handleMessage = (data) => {
			if (data.c === true) {
				// control message
				this.setState({
					hosts: Object.assign(
						this.state.hosts,
						{
							[data.s.i]: Object.assign(this.state.hosts[data.s.i] || {}, data.s)
						}
					)
				})

				return;
			}

			// let ts = parseInt(e.lastEventId.split(':')[0])

			// console.log('got', data, ts)

			data.ts = Date.now();

			this.setState({
				hosts: Object.assign(
					this.state.hosts,
					{
						[data.i]: Object.assign(this.state.hosts[data.i] || {}, data)
					}
				)
			})

			this.updateTimer()
		}
	}

	shouldComponentUpdate (prevProps, prevState) {
		return false;
	}

	componentWillUnmount () {
		clearInterval(this.update);
	}

	render () {
		return (
			<div className={ "hosts " + (this.state.args.embed ? 'embed' : '') } style={{ '--zoom': 1 }}>
				{
					Object.entries(this.state.hosts)
						.sort(
							(a, b) =>
								String(a[0]).localeCompare(b[0], undefined, { numeric: true })
						)
						.map(this._renderHost)
				}
			</div>
		);
	}

	componentDidUpdate () {
		if (this.state.args.embed) {
			document.body.classList.add('embed');
		}
	}

	_renderHost = ([host, data]) => {
		return (
			<div className="host">
				<h1 className={ ((Date.now() / 1000 | 0) - data.ts > 5) ? 'err' : 'ok' }>
					<a href={ "https://my.broadcast.radio/station/" + data.id } target="_blank">
						{ data.n }
					</a>
				</h1>

				<div className="vu">
					{ 
						data.p.map((ch, i) => 
							<div
								className="vu-channel"
								style={
									{
										'--peak': scaled(ch),
										'--rms': scaled(data.r[i])
									}
								}
							></div>
						)
					}

					{
						data.sn ? <div className="vu-warn">⚠</div> : null 
					}
				</div>
			</div>
		)
	}

}
